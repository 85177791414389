<template>
  <div style="background-color: white; height: 100%">
    <van-empty class="custom-image" :image="image" :description="description">
      <van-button
        round
        plain
        type="primary"
        color="#ee502f"
        style="width: 100px"
        class="bottom-button"
        @click="naviToHome"
        >{{ $t("返回首页") }}</van-button
      >
    </van-empty>
  </div>
</template>

<script>
import { Empty, Button } from "vant";
import take_off from "@/assets/images/take_off.png";

export default {
  components: {
    [Empty.name]: Empty,
    [Button.name]: Button,
  },
  data() {
    return {
      image: take_off,
      description: this.$t("该商品已下架"),
    };
  },
  methods: {
    naviToHome() {
      this.$router.push({ name: "Home" });
    },
  },
};
</script>

<style lang="less" scoped>
:deep(.custom-image) {
  padding-top: 200px;
}

:deep(.van-empty__image) {
  width: 60px;
  height: 60px;
}

:deep(.van-empty__description) {
  color: #333;
  font-size: 1rem;
}
</style>